
import Vue from 'vue';
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { FormDefinition, formInputRequired, formIsValidURL } from '../plugins/form-rules'

import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import MainCard from "@/components/MainCard.vue"
import DialogDivider from '../components/DialogDivider.vue';
import { Campaign, DISCOUNT_TYPES, DiscountType } from '@/companyDashboardAPI/Campaign';
import DeleteCampaignDialog from '@/components/DeleteCampaignDialog.vue';
import QrcodeVue from 'qrcode.vue';
import JsBarcode from 'jsbarcode';

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
    DialogDivider: DialogDivider,
    DeleteCampaignDialog: DeleteCampaignDialog,
    QrcodeVue,

  }
})
export default class CampaignEditView extends Vue {
  @Prop({required: true})
  private readonly ApiClient!: CompanyDashboardApi;

  public loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public submittingForm = false;
  public errorMessage = '';
  public discountTypes = DISCOUNT_TYPES;

  public campaignData: Campaign = {
    id: -1,
    name: '',
    dateStart: '',
    dateEnd: '',
    discountType: DISCOUNT_TYPES[0],
    description: '',
    image: '',
    imageURL: '',
    discountCode: '',
    discount: 0,
    validityAltText: '',
    redeemButtonAltText: '',
    appMessageLink: '',
    codeType: 'qr',
    discountIndicatorNumber: 0,
    validityDatesActive: false,
    discountActive: false,
    showRedeemButton: false,
  };
  private OriginalCampaignData!: Campaign;

  public dateStartCalendar = false;
  public dateEndCalendar = false;

  public campaignImageFile: File | null = null;

  public form: FormDefinition = {
    valid: false,
    fields: {},
    rules: {
      uploadCampaignImage: [
        this.uploadCampaignImage()
      ],
      name: [
        this.requiredRule()
      ],
      URL: [
        this.isValidURL()
      ],
      discount: [
        (value: string): boolean | string => {
          return Number(value) > 0 && Number(value) <= 100 || 'Rabattbetrag muss mehr als 0 und weniger als 99,99 betragen.'
        }
      ]
    }
  }

  public confirmDeletion = false;

  get QRCodeText(): string {
    if (!this.form.valid) {
      return '';
    }

    // @TODO: Update the content when its structure is defined.
    return `
      Code: ${this.campaignData.discountCode},
      discount: ${this.campaignData.discount},
      type: ${this.campaignData.discountType.key}.
    `;
  }

  get formattedStartDate(): string {
    if (!this.campaignData.dateStart) {
      return '';
    }

    return new Date(this.campaignData.dateStart).toLocaleDateString('de', { day: "2-digit", month: "2-digit", year: "numeric" });
  }

  get formattedEndDate(): string {
    if (!this.campaignData.dateEnd) {
      return '';
    }

    return new Date(this.campaignData.dateEnd).toLocaleDateString('de', { day: "2-digit", month: "2-digit", year: "numeric" });
  }

  private getFormDiscountType(): DiscountType {
    return this.campaignData.discountType;
  }

  /**
   * Called on mounted
   *
   * Initialize the campaign.
   */
  private async mounted() {
    const campaignDataParam = this.$route.params.campaignData
      ? JSON.parse(this.$route.params.campaignData)
      : null;

    // Load Campaign data and prepare Edit page. No data is preloaded if it's a new campaign.
    if (campaignDataParam) {
      this.loadingText = 'Lade Kampagnendaten...';
      for await(const campaignData of this.ApiClient.getCampaigns()) {
        campaignData.forEach(campaign => {
          if (campaign.id == campaignDataParam.id) {
            this.campaignData = JSON.parse(JSON.stringify(campaign));
            this.OriginalCampaignData = JSON.parse(JSON.stringify(campaign));
          }
        })
      }
    }

    // Stop loading the spinner and show the form
    this.loadingText = ''
    this.$nextTick(() => {
      this.generateBarCode();
    });
  }

  public removeCampaignImage(): void  {
    this.campaignData.image = '';
    this.campaignImageFile = null;
  }

  public selectedCampaignImage(): void {
    this.campaignData.image = this.campaignImageFile ? URL.createObjectURL(this.campaignImageFile) : '';
    this.$nextTick(() => {
      this.generateBarCode();
    });
  }

  /**
   * Save the formdata
   */
  public async save() : Promise<void> {
    this.submittingForm = true;
    this.errorMessage = '';
    let errorMessage = '';
    const campaignData = JSON.parse(JSON.stringify(this.campaignData));

    // Update Campaign data
    if (JSON.stringify(campaignData) != JSON.stringify(this.OriginalCampaignData)) {
      await this.ApiClient.putCampaign(campaignData)
        .catch((error) => {
          errorMessage += 'Die Kampagne konnte nicht gespeichert werden!';
          console.log('error in putCampaign')
          console.log(error)
        })
    }

    this.errorMessage = errorMessage;
    this.submittingForm = false;

    if (errorMessage == '') {
      this.goBack();
    }
  }

  /**
   * Go back to Campaigns view
   */
  public goBack() : void {
    this.$router.push({
      name: 'CampaignsOverview'
    })
  }

  /**
   * Copy the message link to the clipboard.
   */
  public copyAppLink(): void {
    navigator.clipboard.writeText(this.campaignData.appMessageLink);
  }

  public validateDiscount(): void {
    const discountRef = this.$refs.discount as Vue & { validate: () => boolean };
    discountRef.validate();
    this.generateBarCode();
  }

  public showDeletionConfirmation(): void {
    this.confirmDeletion = true;
  }

  public hideDeletionConfirmation(deleted = false): void {
    this.confirmDeletion = false;

    if (deleted) {
      this.goBack();
    }
  }

  public generateBarCode(): void {
    if (this.campaignData.codeType !== 'bar') {
      return;
    }

    // Digit 1 to 2 	26 stands for IPRO barcode
    // Digit 3 to 4 	10 stands for discount operation barcode
    // Digit 5 to 8 	Discount in per cent with 3 digits before and one after the decimal point,
    //                or discount as an amount with 2 digits before and 2 digits after the decimal point
    // Digit 9 to 11 	Discount indicator nr.
    // Digit 12 	    A zero for percentages; a one for amounts

    let code = '0000';
    const [discount, decimal] = this.campaignData.discount.toString().split(/[.,]/);
    if (this.campaignData.discountType.key === '%') {
      code = discount.padStart(3, '0') + (decimal ?? '0');
    } else {
      code = discount.padStart(2, '0') + (decimal ?? '0').padEnd(2, '0');
    }

    const typeDigit = this.campaignData.discountType.key === '%' ? '0' : '1';
    const indicatorNumber = this.campaignData.discountIndicatorNumber.toString().padStart(3, '0');

    JsBarcode(this.$refs.barcode, `2610${code}${indicatorNumber}${typeDigit}`, {
      format: "EAN13",
      displayValue: true,
      width: 2,
      height: 100,
      fontSize: 18,
    });
  }

  /**
   * Check on valid urls
   */
  private isValidURL() {
      return formIsValidURL(this.$vuetify.lang.t(`$vuetify.form_noValidURL`))
    }

  /**
   * Set default Message for required field input
   */
  private requiredRule() {
    return formInputRequired(this.$vuetify.lang.t(`$vuetify.form_required`))
  }

  /**
   * Check upload Campaign image.
   */
  private uploadCampaignImage() {
    return (file: any) => !file || file.size < 1_000_000 || this.$vuetify.lang.t(`$vuetify.form_maxFileSize1MB`)
  }

  public cardTitle(): string {
    return 'Aktionseinstellung';
  }
}
