
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DialogDivider extends Vue {
    @Prop({required: true})
    public readonly title!: string;

    @Prop({default: false})
    public readonly activable!: boolean;

    @Prop({default: false})
    public readonly value!: boolean;

    public onActivateChange(selectedValue: boolean): void {
        this.$emit('input', selectedValue);
    }
}
