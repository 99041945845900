import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/Home', // Darf nicht '/' sein, sonst werden die Parameter zur Anmeldung nicht übergeben!
    name: 'Home',
    component: () => import("../views/HomeView.vue")
  },
  {
    path: '/Company',
    name: 'Company',
    component: () => import("../views/CompanyView.vue")
  },
  {
    path: '/Branches',
    name: 'Branches',
    component: () => import("../views/BranchesView.vue")
  },
  {
    path: '/Branches/Edit',
    name: 'BranchEdit',
    component: () => import("../views/BranchEditView.vue"),
    props: true
  },
  {
    path: '/OrderingRules',
    name: 'OrderingRules',
    component: () => import("../views/OrderingRulesView.vue")
  },
  {
    path: '/ModuleSettings',
    name: 'ModuleSettings',
    component: () => import("../views/ModuleSettingsView.vue")
  },
  {
    path: '/OrderingRules/Edit',
    name: 'OrderingRuleEdit',
    component: () => import("../views/OrderingRuleEditView.vue")
  },
  {
    path: '/Campaigns',
    name: 'CampaignsOverview',
    component: () => import("../views/CampaignsView.vue")
  },
  {
    path: '/Campaigns/Edit',
    name: 'CampaignEdit',
    component: () => import('../views/CampaignEditView.vue'),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/ErrorView.vue"),
    props: true
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
