export interface DiscountType {
  key: '€' | '%';
  value: '€' | '%';
}

export type CodeType = 'qr' | 'bar';

export const DISCOUNT_TYPES: DiscountType[] = [
  { key: '€', value: '€' },
  { key: '%', value: '%' },
];

export interface Campaign {
  id: number;
  name: string;
  description: string;
  image: string;
  imageURL: string;
  discountCode: string;
  discount: number;
  discountType: DiscountType;
  dateStart: string;
  dateEnd: string;
  validityAltText: string;
  redeemButtonAltText: string;
  appMessageLink: string;
  codeType: CodeType;
  discountIndicatorNumber: number; // At the moment, it's used while generating Barcode.
  validityDatesActive: boolean;
  discountActive: boolean;
  showRedeemButton: boolean;
}
