
import { Campaign } from "@/companyDashboardAPI/Campaign";
import { CompanyDashboardApi } from "@/companyDashboardAPI/CompanyDashboardAPI";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DeleteCampaignDialog extends Vue {
  @Prop({ required: true })
  public readonly ApiClient!: CompanyDashboardApi;

  @Prop({ required: true })
  public showDialog = false;

  @Prop({ required: true })
  public campaign!: Campaign;

  public isDeleting = false;

  public async deleteCampaign(): Promise<void> {
    this.isDeleting = true;
    await this.ApiClient.deleteCampaign(this.campaign.id);
    this.isDeleting = false;
    this.$emit('close', true);
  }

  public closeConfirmation(): void {
    this.isDeleting = false;
    this.$emit('close', false);
  }
}
