
import Vue from 'vue'
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';

import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import MainCard from '@/components/MainCard.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { Campaign } from '@/companyDashboardAPI/Campaign';

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
  }
})
export default class CampaignsView extends Vue {
  @Prop({required: true})
  public readonly ApiClient!: CompanyDashboardApi;

  private cardTitle = 'Aktionen';

  public loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public campaigns: Campaign[] = [];

  private async mounted() {
    this.cardTitle = 'Aktionen';

    for await (const campaigns of this.ApiClient.getCampaigns()) {
      this.campaigns = campaigns;
    }

    this.loadingText = '';
  }

  public getCardTitle() : string {
    return this.cardTitle
  }

  public editCampaign(campaign: Campaign) : void {
    this.$router.push({
      name: 'CampaignEdit',
      params: {
        campaignData: JSON.stringify(campaign)
      }
    })
  }

  public addNewCampaign(): void {
    this.$router.push({
      name: 'CampaignEdit',
    });
  }

  public getFormattedDate(date: string): string {
    if (!date) {
      return '';
    }

    // Format days to use leading zero.
    return new Date(date).toLocaleDateString('de', { day: "2-digit", month: "2-digit", year: "numeric" });
  }
}
